import '../styles/index.scss';
import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { fadeIn, fadeOut } from '../components/Animations';

const ThankYou = () => {
	const [ isFirefox, setIsFirefox ] = useState(false);
	const shopNowButtonLines = useRef();
	const shopNowButton = useRef();
	const handleHoverButton = () => {
		fadeIn(shopNowButtonLines.current);
	};
	const handleHoverExitButton = () => {
		fadeOut(shopNowButtonLines.current);
	};
	// firefox
	let browserIsFirefox = typeof InstallTrigger !== 'undefined';
	if (browserIsFirefox) setIsFirefox(true);
	return (
		<div className="thankyou-container">
			<div className="thankyou-inner-container">
				<div className="thankyou-title">Welcome to the Cat & Bear family!</div>
				<div className="thankyou-message">
					<Link to="/">
						<svg
							onMouseOver={() => handleHoverButton()}
							onMouseLeave={() => handleHoverExitButton()}
							ref={shopNowButton}
							className="thankyou-back-button"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 119.27 52.66"
						>
							<g id="button" strokeMiterlimit="10">
								<rect
									x="10.9"
									y="11.26"
									width="97.08"
									height="29.78"
									rx="4.14"
									ry="4.14"
									fill="#22b2f2"
									stroke="#354356"
								/>
								{isFirefox && (
									<text
										transform="translate(32.21 29.25)"
										fontSize="12.26"
										fill="#fff"
										stroke="#fff"
										fontFamily="BigJohnPRO-Regular, Big John PRO"
										letterSpacing=".11em"
									>
										{/* Shop N<tspan x="47.65" y="0" letterSpacing=".09em">o</tspan><tspan x="54.6" y="0">w</tspan>
                  <tspan x="47.65" y="0" letterSpacing=".09em">o</tspan><tspan x="54.6" y="0">w</tspan> */}
										Back → →
									</text>
								)}
								{!isFirefox && (
									<text
										transform="translate(27.21 29.25)"
										fontSize="12.26"
										fill="#fff"
										stroke="#fff"
										fontFamily="BigJohnPRO-Regular, Big John PRO"
										letterSpacing=".11em"
									>
										{/* Shop N<tspan x="47.65" y="0" letterSpacing=".09em">o</tspan><tspan x="54.6" y="0">w</tspan>
                  <tspan x="47.65" y="0" letterSpacing=".09em">o</tspan><tspan x="54.6" y="0">w</tspan> */}
										← Back ←
									</text>
								)}
							</g>
							<g
								ref={shopNowButtonLines}
								id="thankyou-back-button-lines"
								fill="none"
								stroke="#181818"
								strokeMiterlimit="10"
							>
								<path d="M88.04 51.32l-2.48-6.63M56.49 6.81L54 .18M65.92 50.63l2.7-6.29M49.14 52.62l-.48-6.82M98.83 6.9L98.35.08M27.27 51.11l3.42-5.92M36.04 7.13l3.42-5.92M119.18 18.41l-6.72 1.29M6.81 31.87l-6.72 1.3M110.19 42.54l3.77 2.97M72.21 2.68l3.77 2.98M12.62 4.65l3.77 2.98M113.95 34.05l3.7-3.06M1.22 20.58l3.7-3.05M6.24 48.52l3.05-3.71" />
							</g>
						</svg>
					</Link>
				</div>
				<svg
					className="thankyou-catbear"
					id="Layer_1"
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 279.21 96.06"
				>
					<path
						d="M117.37 31.45s-1.06-3.26-.46-5.24a6.06 6.06 0 015.88-3.51s3.41.32 4.21 3c0 0 10.32-1.77 16.44 4.79 0 0 3.18-3 5.59.37 0 0 3.16 5.4-2.93 7.1 0 0-.4 15-18.3 11 .04.01-16.78-2.32-10.43-17.51z"
						fill="#fff"
						stroke="#344357"
						stroke-miterlimit="10"
						stroke-width=".75"
					/>
					<path
						d="M118.62 44.51s-5.21 6.29-5.66 14.1c0 0-1.64 9.92 2.83 14.56 0 0 12.08 6.65 25.29-2.27 0 0 1-10.61.4-14.19 0 0 10.13-.28 10.53-3.59a2.31 2.31 0 00-2.18-3s-5.29-.74-8.35-3c0 0-3.36 3.84-12.19 2.12 0 .02-6.38-.6-10.67-4.73z"
						fill="#fff"
						stroke="#344357"
						stroke-miterlimit="10"
						stroke-width=".75"
					/>
					<path
						d="M152.07 51.53a2.8 2.8 0 01-1.39 3.28c-1.94 1.49-9.2 1.9-9.2 1.9.66 1.79-.4 14.19-.4 14.19 4.08 4 17.15 1.65 17.15 1.65 5.26-.14 5.45-3.66 5.45-3.66a65.91 65.91 0 000-6.85 22.3 22.3 0 00-3.69-12.39M121.79 68.49a3.13 3.13 0 013.06-3.15s3.18-.05 3.06 3.69c0 0-.55 2.56-2.72 2.6a3.16 3.16 0 01-3.4-3.14z"
						fill="#fff"
						stroke="#344357"
						stroke-miterlimit="10"
						stroke-width=".75"
					/>
					<path
						d="M150.73 67.02s-.42-1.65 2-1.68c0 0 3.47-.31 5.43-2.35 0 0 1-.92 1-1.39 0 0 2.4-4.22 3.08-4.33a8 8 0 016.11-2.44s2.4 1.45-.78 2.56c0 0-3.52.6-4.82 3.52 0 0-2.23 4.28-3.71 5.08a8.36 8.36 0 01-6.31 2.53 1.79 1.79 0 01-2-1.5zM149.89 33.62s4.4-.18 7.33 1.32c0 0 3.49-2.24 5-2 0 0 2.18 1.58 1.23 7 0 0 2.35 5.65-3.44 9.71a15.79 15.79 0 01-7.92 1.88s-.21-1-2.24-1.37c0 0-5.13-.58-8.25-2.94a.08.08 0 010-.14c.63-.4 4.05-2.85 4.56-9.13-.02 0 3.81-.77 3.73-4.33z"
						fill="#fff"
						stroke="#344357"
						stroke-miterlimit="10"
						stroke-width=".75"
					/>
					<path
						d="M113.15 67.73S92.36 73.61 86.22 84.9c0 0-53.42 1.18-72.48 3.46M163.68 68.89s13.42 3.26 28.1 16c0 0 65.43 1.75 73.68 3.46"
						fill="none"
						stroke="#344357"
						stroke-miterlimit="10"
						stroke-width=".75"
					/>
					<path fill="#fff" stroke="#fff" stroke-miterlimit="10" d="M.5 88.36h278.21v7.2H.5z" />
					<path
						d="M138.49 3.4s1.64-4.23 5.22-2.65c0 0 5.51 4.17-5.2 10.19 0 0-8.85-4.47-6.24-8.82 0-.02 3.71-4.69 6.22 1.28zM147.03 10.1s1-1.79 2-.72a2.35 2.35 0 01-.49 2s-1 1.22-.76 1.6c0 0-2.58-.23-2.7-2 .05-.04-.14-1.88 1.95-.88z"
						fill="none"
						stroke="#181818"
						stroke-miterlimit="10"
						stroke-width=".75"
					/>
				</svg>
			</div>
		</div>
	);
};

export default ThankYou;
